@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@forward '@chicksgroup/styles/colors';

#cx-seo-dropdown {
    --cds-collapse-height: 48px;
    --cds-collapse-max-height: 48px;
    --cds-collapse-header-padding: 12px 20px;
    --cds-collapse-header-gap: 8px;
    --cds-collapse-header-outline-container: 1px solid var(--cg-grey-400);
    --cds-collapse-header-outline: none;
    --cds-collapse-header-border-radius: 4px;
    --cds-collapse-border-radius: 4px;
    --cds-collapse-open-icon-color: var(--cg-grey-700);
    --cds-icon-color: var(--cg-grey-700);
    --cds-collapse-transition-time: 0.75s;
    --cds-collapse-icon-rotate-duration: 0.75s;
    --cds-collapse-transition-timing-function: cubic-bezier(0.17,0,0.38,0.99);
    --cds-collapse-content-background-color: var(--cg-grey-100);
    --cds-collapse-background-color: var(--cg-grey-100);
    --cds-collapse-background-color-hover: var(--cg-grey-100);
    --cds-collapse-background-color-active: var(--cg-grey-100);
    --md-icon-size: 16px;
    --cds-collapse-hover-dropdown-icon-opacity: 1;
    --cds-collapse-open-dropdown-icon-opacity: 1;
    --cds-collapse-active-dropdown-icon-opacity: 1;
    --cds-collapse-dropdown-icon-opacity: 0;
    --md-filled-button-container-shadow-color: transparent;

    @media (width >= v.$desktop) {
        --cds-collapse-background-color-hover: var(--cg-grey-300);
        --cds-collapse-background-color-active: var(--cg-grey-100);
    }

    .collapse-content {
        padding: v.$regularPad;        
        visibility: visible;
        height: auto;
        background-color: var(--cg-grey-100);

        &.closed {
            visibility: hidden;
            height: 0;
            overflow: hidden;
            position: absolute;
            clip-path: inset(0 0 0 0);
            white-space: nowrap;
        }

        @media (width >= v.$extrawidescreen) {
            padding: v.$regularPad v.$regularPad 12px;   
        }
        
        h2 {            
            @include m.font(v.$extralarge, 500, v.$huge, 0.01em, var(--cg-grey-800));
            padding-top: v.$regularPad;

            &:first-of-type {
                padding-top: 0;
            }
        }

        h3 {
            @include m.font(v.$largeplus, 500, v.$extralarge, 0.01em, var(--cg-grey-800));
        }

        p {
            @include m.font(v.$medium, 400, v.$moreplushuge, 0.015em, var(--cg-grey-600));

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        h2, h3, h4, p {
            margin-bottom: v.$regularPad;
        }

        ul, ol {
            margin-left: v.$bigPad;
            margin-bottom: v.$regularPad;

            li {
                @include m.font(v.$medium, 400, v.$moreplushuge, 0.015em, var(--cg-grey-600));
            }
        }
        
        hr {
            margin: v.$bigPad 0;
            color: rgba(231, 231, 231, 1);
            opacity: 1;
        }

        a:not(.learn-more-link, .learn-more-link-extra-card){
            color: var(--cx-secondary-700);
            
            @media (width >= v.$desktop) {
                &:hover {
                    text-decoration: underline !important;
                }                
            }
        }
    }

    .collapse-header {
        &__icon {
            color: var(--cx-secondary-600);
            font-size: v.$extralarge;
        }

        &__title {
            @include m.font(v.$regular, 400, v.$medium, 0.0025em, var(--cg-grey-700));
            margin: 0;
            font-family: v.$primaryfont;
        }
    }
}
