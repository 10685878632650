@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#cx-table {
    --header-width: 116px;
    --mobile-header-width: 96px;

    @include m.flex-gap(v.$regularPad, $direction: column);

    .table-headers {
        @include m.flex($justify: space-between);
        margin-inline: 15px;

        > p {
            font: 300 #{v.$small}/12px v.$primaryfont;
            letter-spacing: 0.384px;
            text-align: left;
            margin-bottom: 0 !important;
            color: v.$gray-default;
            text-transform: uppercase;
        }
    }

    .list-container {
        @include m.flex-gap(v.$regularPad, $direction: column);

        @media (width < v.$desktop) {
            gap: v.$smallPad;
        }
    }

    .id-header-width {
        @include m.container-fluid(156px);
        padding-right: 35px;

        @media (width <= v.$desktop) {
            padding-right: 0;
            max-width: var(--mobile-header-width);
        }
    }

    .column-header-width {
        @include m.container-fluid(var(--header-width));

        @media (width <= v.$desktop) {
            max-width: var(--mobile-header-width);
        }
    }

    .status-header-width {
        @include m.container-fluid(129px);

        @media (width <= v.$desktop) {
            max-width: var(--mobile-header-width);
        }
    }

    .subscription-header-width {
        @include m.container-fluid(310px);

        @media (width <= v.$desktop) {
            max-width: var(--mobile-header-width);
        }
    }

    .valid-column-header-width {
        @include m.container-fluid(196px);

        @media (width <= v.$desktop) {
            max-width: var(--mobile-header-width);
        }
    }
}
