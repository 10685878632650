@import 'styles/variables';

#cx-select {
    --cds-icon-color: var(--cg-grey-700);

    button {
        background-color: var(--cg-grey-250);
        font-weight: 400;
        width: 100%;
        display: flex;
        gap: $extra-tiny;
        padding: $extra-tiny $medium;

        mdc-icon {
            color: $purple-text;
        }

        cds-icon {
            transform: rotate(0deg);
            transition: 0.3s ease;
        }

        &.show {
            cds-icon {
                transform: rotate(180deg);
            }
        }

        .selected-option-wrapper {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: start;

            label {
                font-size: $small;
                color: rgba(107, 107, 107, 1);
                letter-spacing: 0.24px;
            }

            .selected-option {
                font-size: $regular;
                color: var(--cg-grey-900);
                letter-spacing: 0.035px;
            }
        }

        &:hover {
            color: $input-placeholder-hover;
            outline: 1px solid $color-secondary-600;
            background-color: var(--cg-grey-300);
        }

        &:focus,
        &:focus-within {
            outline: 2px solid $color-secondary-600;
            background-color: var(--cg-grey-350);
        }
    }

    .dropdown-menu {
        color: rgba(82, 82, 82, 1);
        font-size: $regular;
        line-height: $medium;
        font-weight: 400;
        gap: $medium;
        padding: 0;
        right: 0 !important;

        .select-option-item {
            display: flex;
            align-items: center;
            padding: $medium;
            gap: $extra-tiny;
            cursor: pointer;

            mdc-icon {
                color: $purple-text;
            }

            &:hover {
                background-color: $secondarycolor;
            }
        }
    }
}
