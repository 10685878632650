@import 'styles/variables';

#cx-status-tag {
    text-transform: capitalize;
    font-weight: 500;
    font-size: $regular;
    min-width: 110px;
    width: fit-content;

    .tag {
        padding: 6px 6px;
        border-radius: 12px;
        text-align: center;
        height: 30px;
    }

    .completed, .complete, .received, .activated, .active {
        background-color: $tag-green;
        color: $success-green;
    }

    .pending, .draft, .marked-sent, .chargeback {
        background-color: $faded-yellow;
        color: $tag-yellow;
    }

    .rejected {
        background-color: $off-red;
        color: $red;
    }
    
    .refunded {
        background-color: $off-light-blue;
        color: $light-blue;
    }

    .refund-requested,
    .partially-refunded,
    .partially-delivered,
    .partial-refund-requested {
        background-color: $off-brown !important;
        color: $brown !important;
    }
    
    .created {
        background-color: $off-purple-text;
        color: $purple-text;
    }

    .pending-response {
        background-color: $mustard;
        color: $lighterbrown;
    }

    @media (max-width: $tablet) {
        font-size: $small;

        .tag {
            padding: 5px 6px;
        }
    }

    @media (max-width: $phone) {
        font-size: $tiny;

        .tag {
            padding: 4px 6px;
        }
    }
}

#cx-verification-status-tag {
    .tag {
        height: 36px;
        padding: 4px;
        border-radius: 4px;
        font-size: $small;
    }

    .verified {
        background-color: $off-green;
        border: 1px solid rgba(0, 174, 103, 0.2);
        color: $darkergray;
    }

    .pending {
        background-color: rgba(87, 98, 213, 0.1);
        border: 1px solid $offlilac;
        color: $darkergray;
    }
}
