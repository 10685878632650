@use '@material/textfield';
@import 'styles/variables';

#cx-support-issue-input {
    height: 144px;
    background-color: $inputgray;
    padding: $tiny;
    border-radius: $small;

    & > div.position-absolute.w-100 {
        z-index: 1;
    }

    &:not(.disabled-area).hover-drop-zone {
        .text-area {
            background-color: $transparent;

            & .mdc-text-field__resizer .mdc-text-field__input {
                color: $transparent;
            }
        }

        #wrapper-sm {
            display: none !important;
        }

        #drop-zone {
            border: 1px dashed $purple-text;
            display: flex;
        }
    }

    @media (min-width: $tablet) {
        &:not(.disabled-area):hover {
            .drop-zone-sm label {
                display: flex;
            }
        }
    }

    @media only screen and (max-width: $tablet) {
        .drop-zone-sm label {
            display: flex !important;
        }
    }

    @media only screen and (max-width: $phone) {
        height: 168px;
    }

    .refresh-icon {
        width: $largeplusplus;
        height: $largeplusplus;
        color: $purple-text;
        top: -8px;
        right: 20px;
        -webkit-animation: spin 0.7s linear infinite;
        -moz-animation: spin 0.7s linear infinite;
        animation: spin 0.7s linear infinite;

        @media (max-width: $phone) {
            top: 6px;
            right: 28px;
        }
    }

    .attach-icon {
        width: 11px;
        height: $largeplusplus;
    }

    .text-area {
        height: 90px;
        width: calc(100% - 12px);
        background-color: $backgroundcontrol;

        .mdc-text-field__resizer {
            resize: none;
            padding: $tiny;

            .mdc-text-field__input {
                margin: 0;
                padding: 0;
            }
        }

        @media (max-width: $phone) {
            height: 115px;

            .mdc-text-field__resizer {
                padding: 5px;
            }
        }
    }

    textarea::-webkit-scrollbar {
        width: $largeplus;
    }

    textarea::-webkit-scrollbar-thumb {
        background-clip: content-box;
        background-color: $purple-text;
        border: 7px solid transparent;
        border-radius: $largeplus;
    }

    .drop-zone-sm {
        min-width: $extralargeplus;

        & label {
            display: none;
        }
    }

    .drop-zone-lg {
        z-index: -1;
        width: 206px;

        & img {
            margin-bottom: $large;
            width: 73.49px;
            height: 56.35px;
        }

        & .uploading-text {
            margin-bottom: $regularplus;
            color: $offblack;
        }

        & .drop-file-text {
            font-size: $medium;
            line-height: $largeplus;
            color: rgba(74, 45, 174, 1);

            @media (max-width: $tablet) {
                font-size: $regular;
            }
        }

        & mdc-linear-progress {
            & > .mdc-linear-progress__buffer {
                background: linear-gradient(0deg, rgba(147, 147, 147, 1), rgba(147, 147, 147, 1)), $progressgray;
            }

            & .mdc-linear-progress__bar.mdc-linear-progress__primary-bar,
            & .mdc-linear-progress__bar.mdc-linear-progress__secondary-bar {
                & > .mdc-linear-progress__bar-inner {
                    border-color: rgba(49, 15, 162, 1);
                }
            }
        }
    }

    .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mdc-list-item__content .file-name {
        @extend .file-name;
    }

    #wrapper-sm {
        padding-top: 0;
        padding-left: 3px;
        height: 42px;
        max-width: 370px;

        @media (max-width: $tablet) {
            padding-left: 4px;
            padding-top: 0;
        }

        @media (max-width: $phone) {
            max-width: 265px;
            padding-left: 0;
            padding-top: 0;
        }

        & .cx-first-files-list {
            width: 90%;
            flex-basis: fit-content;

            & .mdc-list {
                list-style-type: none;
                display: flex;

                & .mdc-list-item {
                    padding-left: 2px;
                    padding-right: 2px;
                    margin-right: 16px;
                    max-width: max-content;
                    flex: 1 1 0;

                    &.mdc-list-item--with-one-line {
                        float: left;
                        height: $extralargeplus;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.05);
                            border-radius: 4px;
                        }
                    }
                }
            }

            & .mdc-list-item__content {
                pointer-events: auto;
            }
        }

        & .cx-dropdown-files-list {
            max-width: 15rem;
            padding: 0.25rem;
            transform: translate(0, 25px) !important;
            inset: 0 0 auto auto !important;

            & .mdc-list-item {
                padding-left: 0;

                &.mdc-list-item--with-one-line {
                    height: auto;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.05);
                        border-radius: 4px;
                    }
                }
            }

            & .mdc-list-item__content {
                pointer-events: auto;
                max-width: 170px;
            }
        }

        .cx-dropdown-files-list {
            overflow: auto;
            max-height: 210px;

            &::-webkit-scrollbar-track {
                background-color: $white;
            }

            &::-webkit-scrollbar {
                width: $largeplus;
            }

            &::-webkit-scrollbar-thumb {
                background-clip: content-box;
                background-color: rgba(176, 176, 176, 0.5);
                border: 7px solid $white;
                border-radius: $largeplus;

                &:hover {
                    background-color: rgba(176, 176, 176, 0.6);
                }

                &:active {
                    background-color: rgba(176, 176, 176, 0.7);
                }
            }
        }

        div.dropend {
            width: $extralargeplus;
            margin-left: 2px;

            @media (max-width: $tablet) {
                left: -13px;
            }
        }
    }

    #drop-zone {
        display: none;
        height: 121px;
        width: calc(100% - 20px);
        box-sizing: border-box;
        border-radius: $tiny;
        position: absolute;
        align-items: center;
        justify-content: center;
        z-index: 0;
        background-color: rgba(49, 15, 162, 0.05) !important;

        @media (max-width: $tablet) {
            border: none;
            align-content: end;
            display: contents;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            margin: 0;
        }
    }

    &.cx-form-control.message-area {
        @include textfield.hover-outline-color($transparent);

        &:hover:not(.disabled-area) {
            outline: 1px solid $purplehover;
        }

        &:has(.mdc-text-field--focused):not(.disabled-area) {
            outline: 1px solid $purple-text !important; 
            @include textfield.ink-color($black);
        }

        &.disabled-area {
            background-color: $divisor-gray-light !important;

            .text-area {                
                background-color: $transparent !important;
            }
        }
    }    
}
