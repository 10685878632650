@use '@material/textfield';
@use 'styles/variables' as var;

#cx-text-input {
    position: relative;

    .mini-spinner-icon {
        position: absolute;
        top: 12px;
        right: 12px;
        --md-circular-progress-size: 25px;
        --md-circular-progress-active-indicator-color: rgba(49, 15, 162, 1);
    }

    .border-fixed {
        border-radius: 4px !important;
    }
    
    .cx-search-input {
        background-color: var.$white !important;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
        @include textfield.outline-shape-radius(12px);
        @include textfield.ink-color(var.$darkergray);

        &.mdc-text-field--focused {
            @include textfield.ink-color(var.$black);
        }

        &.no-shadow {
            box-shadow: none;
        }
    }

    .button-search {
        color: var.$darkergray-default;

        &:hover {
            color: var.$darkergray;
        }

        &:active {
            color: var.$purple-text;
        }

        &.default-button {
            color: var.$purple-text !important;
        }
    }

    .material-icon-button {
        background: transparent;
        border: none;
        cursor: pointer;
    }

    &.outline-styles {
        .mdc-notched-outline--no-label {
            display: none;
        }

        &:hover {
            border-radius: 4px;
            outline: 1px solid var.$purplehover;

            &.input-outline--error {
                outline: 1px solid var.$red-hover;
            }

            &.input-outline--success {
                outline: 1px solid var.$success-green-hover;
            }

            &.input-outline--warning {
                outline: 1px solid var.$yellow-hover;
            }
        }

        &.input-outline--error {
            border-radius: 4px;
            outline: 1px solid var.$red;

            .material-icons {
                color: var.$red;
            }
        }

        &.input-outline--success {
            border-radius: 4px;
            outline: 1px solid var.$success-green;

            .material-icons {
                color: var.$success-green;
            }
        }

        &.input-outline--warning {
            border-radius: 4px;
            outline: 1px solid var.$yellow;
        }
    }

    .moveOut {
        width: 0 !important;
        padding: 0 !important;
    }

    &.secondary-input {
        display: flex;
        height: 50px !important;

        .material-icons {
            position: absolute;
            right: 0;
            top: 5px;
            font-size: var.$medium !important;
            color: var.$purple-text;
        }

        > .cx-search-input {
            height: 50px !important;
            background-color: var.$dropdownmenu !important;
            box-shadow: none;
            border-radius: 4px !important;
            @include textfield.outline-shape-radius(4px);
            width: 100%;

            &.additional-input {
                width: 220px;
                transition: width 200ms ease, padding 200ms ease;
                transition-delay: 0ms;

                input {
                    max-width: 85px !important;
                }
            }

            &.inline-parent {
                input {
                    max-width: 130px !important;

                    &:focus {
                        max-width: 220px !important;
                    }
                }
            }
        }

        :nth-child(3) {
            &.additional-input {
                width: 222px
            }
        }

        &:hover {
            border-radius: 4px;
            outline: 2px solid var.$purplehover;

            &.input-outline--error {
                outline: 2px solid var.$red-hover;
            }

            &.input-outline--success {
                outline: 2px solid var.$success-green-hover;
            }

            &.input-outline--warning {
                outline: 2px solid var.$yellow-hover;
            }
        }

        &.input-outline--error {
            border-radius: 4px;
            outline: 2px solid var.$red;

            .material-icons {
                color: var.$red;
            }
        }

        &.input-outline--success {
            border-radius: 4px;
            outline: 2px solid var.$success-green;

            .material-icons {
                color: var.$success-green;
            }
        }

        &.input-outline--warning {
            border-radius: 4px;
            outline: 2px solid var.$yellow;
        }

        .input-password {
            @include textfield.label-color(var.$semi-black);
            @include textfield.ink-color(var.$black);
            background-color: var.$inputgray;
            font-size: var.$regular;
            padding-top: var.$smallPad !important;
            transition: width 0.3s ease;
            height: 50px !important;

            &__additional-input {
                padding-left: 0 !important;
            }

            &.mdc-text-field--disabled {
                .mdc-text-field__input {
                    color: var.$black;
                }

                .mdc-floating-label {
                    color: var.$semi-black;
                }
            }

            &.mdc-text-field--label-floating {
                @include textfield.label-color(var.$semi-black);
                font-size: var.$small;
            }

            mdc-notched-outline {
                & > .mdc-notched-outline__leading,
                & > .mdc-notched-outline__trailing {
                    display: none;
                }
            }

            input {
                height: 50px !important;
                font-size: var.$regular !important;
                text-overflow: ellipsis;
                position: absolute;
                max-width: calc(100% - 60px);
            }
        }

        .mdc-floating-label {
            font-size: var.$regular;
            text-overflow: clip;
            overflow: visible;
            left: 15px;
        }

        .using-label .mdc-notched-outline--no-label .mdc-notched-outline__notch {
            display: block;
        }

        .cx-form-control {
            @include textfield.focused-outline-color(var.$transparent);
            @include textfield.hover-outline-color(var.$transparent);
            @include textfield.outline-color(var.$transparent);
        }

        .mdc-notched-outline .mdc-floating-label--float-above,
        .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
        .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
            transform: translateY(-20px) !important;
            font-size: var.$small;
        }

        .circle-icon,
        .mini-spinner-icon {
            right: 15px;
            top: 16px;
        }

        .mdc-circular-progress__determinate-circle,
        .mdc-circular-progress__indeterminate-circle-graphic {
            stroke: var.$purple-text;
        }
    }

    .quantity-input {
        box-shadow: none !important;
        background-color: var.$inputgray !important;
    }
}
