$primaryfont: "Roboto", sans-serif;
$secondaryfont: "Playfair Display", serif;

$materialfontOutlined: 'Material icons outlined';
$materialfont: 'Material icons';
$extra-tiny: 8px;
$tiny: 10px;
$extrasmall: 10px;
$small: 12px;
$smallplus: 13px;
$regular: 14px;
$regularplus: 15px;
$medium: 16px;
$mediumplus: 17px;
$large: 18px;
$largeplus: 20px;
$largeplusplus: 22px;
$extralarge: 24px;
$extralargeplus: 25px;
$extralargeplusplus: 26px;
$huge: 28px;
$morehuge: 30px;
$moreplushuge: 32px;
$hugeplus: 34px;
$hugeplustitle: 35px;
$extrahuge: 36px;
$extrahugemedium: 38px;
$extrahugetitle: 40px;
$extrahugeplus: 48px;
$extrahugeplustitle: 50px;
$giantmedium: 60px;
$extraextrahuge: 70px;
$giantsmall: 80px;
$giant: 100px;
$extragiant: 150px;

$slateblue-off-lower-opacity: rgba(76, 96, 133, .15);
$slateblue-off-low-opacity: rgba(76, 96, 133, .2);
$slateblue-off-medium-opacity: rgba(76, 96, 133, .4);
$slateblue-off-high-opacity: rgba(76, 96, 133, .6);
$stateblue-off-medium-non-opacity: rgba(70,87,120,1);
$mediumgray: rgba(255, 255, 255, 0.5);

$cx-purple: rgba(67, 0, 169, 1);
$cx-purple-half: rgba(67, 0, 169, .5);
$cx-primary-hover: rgba(35, 11, 117, 1);
$cx-blue: rgba(0, 51, 140, 1);
$cx-blue-hover: rgba(0, 28, 77, 1);
$cx-gray: rgba(229, 229, 229, 1);
$select-gray: rgba(242, 242, 242, 1);
$odd-gray: rgba(247, 245, 252, 1);
$yellow: rgba(254, 215, 102, 1);
$ghost-white: rgba(246, 246, 253, 1);
$transparent-blue: rgba(221, 221, 255, 1);
$mustard: rgba(226, 160, 21, 0.3);
$off-yellow: rgba(254, 215, 102, 0.2);
$tag-yellow: rgba(211, 144, 3, 1);
$faded-yellow: rgba(212, 215, 80, 0.35);
$slategrey: rgba(84, 101, 133, 1);
$slateblue: rgba(132, 155, 198, 1);
$blue: rgba(2, 50, 141, 1);
$light-blue: rgba(0, 143, 174, 1);
$off-light-blue: rgba(0, 143, 174, 0.3);
$faq-card-shadow: rgba(44, 45, 51, 0.08);
$gray-blue: rgba(86, 89, 116, 1);
$subtitle-gray: rgba(158, 159, 176, 1);
$dark-blue: rgba(0, 46, 128, 1);
$navy-blue: rgba(10, 44, 145, 1);
$green: rgba(57, 226, 157, 1);
$notification-green: rgba(76, 233, 165, 1);
$success-green: rgba(0, 174, 103, 1);
$success-green-hover: rgba(0, 174, 103, 0.5);
$tag-green: rgba(90, 213, 87, 0.3);
$off-green: rgba(0, 174, 103, 0.1);
$red: rgba(253, 74, 74, 1);
$red-color: rgba(255, 0, 0, 1);
$red-text: rgba(255, 0, 0, 0.75);
$red-hover: rgba(253, 74, 74, 0.5);
$red-error: rgba(216, 0, 39, 1);
$red-error-hover: rgba(216, 0, 39, 0.5);
$notification-red: rgba(251, 74, 74, 1);
$off-red: rgba(255, 0, 0, 0.2);
$red-destructive: rgba(255, 0, 0, 0.1);
$red-destructive-pressed: rgba(255, 0, 0, 0.3);
$brown: rgba(162, 39, 0, 1);
$lighterbrown: rgba(137, 98, 15, 1);
$off-brown: rgba(162, 39, 0, 0.35);
$white: rgba(255, 255, 255, 1);
$white-hover: rgba(216, 216, 255, 1);
$white-pressed: rgba(163, 163, 255, 1);
$shadow: rgba(44, 45, 51, 0.8);
$lightergray: rgba(250, 250, 255, 1);
$lightwhite: rgba(250, 252, 255, 1);
$lightgray: rgba(255, 255, 255, 0.75);
$midgray: rgba(112, 112, 112, 1);
$inputgray: rgba(249, 249, 249, 1);
$regulargray: rgba(255, 255, 255, 0.6);
$divisor-gray: rgba(196, 196, 196, 0.6);
$divisor-gray-light: rgba(196, 196, 196, 0.3);
$divisor-gray-dark: rgba(196, 196, 196, 1);
$medium-gray: rgba(128, 128, 128, 1);
$palegray: rgba(243, 245, 247, 1);
$gray: rgba(255, 255, 255, 0.5);
$medium-dark-gray: rgba(255, 255, 255, 0.35);
$darkgray: rgba(255, 255, 255, 0.2);
$jetgray: rgba(43, 44, 59, 1);
$darkergray: rgba(86, 89, 116, 1);
$darkergray-default: rgba(86, 89, 116, 0.7);
$darkergray-medium: rgba(86, 89, 116, 0.5);
$progressgray: rgba(214, 214, 214, 1);
$transparent: rgba(0, 0, 0, 0);
$softblack: rgba(70, 70, 70, 1);
$mediumblack: rgba(51, 51, 51, 1);
$black: rgba(0, 0, 0, 1);
$light-black: rgba(0, 0, 0, .75);
$semi-black: rgba(0, 0, 0, .5);
$faded-black: rgba(0, 0, 0, 0.3);
$purple-text: rgba(49, 15, 162, 1);
$off-purple-text: rgba(46, 0, 144, 0.3);
$dark-purple: rgba(44, 0, 111, 1);
$lilac: rgba(87, 97, 213);
$lilac-action: rgba(84, 59, 239, 1);
$lilac-hover: rgba(241, 238, 250, 1);
$lilac-hover-medium: rgba(241, 238, 250, 0.5);
$lighterlilac: rgba(167, 135, 215, 1);
$lilac-btn: rgba(163, 138, 244, 1);
$offlilac: rgba(87, 98, 213, 0.2);
$purplehover: rgba(49, 15, 162, 0.5);
$light-back: rgb(18, 18, 18);
$black-low-opacity: rgba(0, 0, 0, 0.1);
$input-placeholder: rgba(0, 0, 0, 0.4);
$semi-transparent-black: rgba(0, 0, 0, 0.5);
$input-placeholder-hover: rgba(0, 0, 0, 0.6);
$dropdownprimarybackground: rgb(43, 44, 60);
$dropdownsecondarybackground: rgb(60, 61, 77);
$section-title: rgba(143, 145, 147, 1);
$pending-yellow-font: rgba(253, 215, 102, 1);
$pending-yellow-background: rgba(254, 215, 102, 0.1);
$yellow-hover: rgba(254, 215, 102, 0.5);
$select-background: rgba(244, 244, 254, 1);
$dropdownhighlight: $yellow;
$dropdownunhighlight: rgb(150, 151, 156);
$backgroundcontrol: rgba(249, 249, 249, 1);
$cx-purple-gradient: linear-gradient(to right,
        $cx-purple 2.45%,
        $cx-blue 101.9%);
$cx-purple-gradient-hover: linear-gradient(to right,
        $cx-primary-hover 2.45%,
        $cx-blue-hover 101.9%);
$cx-progress-gradient: linear-gradient(to right,
        $cx-purple 50%,
        rgba(197, 204, 229, 1) 50%);
$cx-blue-gradient: linear-gradient(91.26deg, #4301A9 1.1%,
 #3C06A6 98.98%);
$cx-blue-second-gradient: linear-gradient(to right, $cx-purple 2.45%, $cx-blue 101.9%);
$primarybackground: rgba(55, 56, 75, 1);
$offblack: rgba(55, 56, 75, 1);
$secondarybackground: rgba(48, 49, 65, 1);
$tertiarybackground: rgba(60, 61, 77, 1);
$hoverbackground: rgba(59, 67, 92, 1);
$primarycolor: rgba($white, 1);
$primaryborder: rgba(76, 96, 133, 1);
$secondaryborder: rgba(76, 96, 133, 0.1);
$secondarycolor: rgba(239, 239, 253, 1);
$secondarycolor-half: rgba(239, 239, 253, .5);
$secondarycolor-hover: rgba(216, 216, 255, 1);
$secondarycolor-text: rgba(12, 42, 146, 1);
$secondarycolor-pressed: rgba(221, 221, 255, 1);
$purple-disabled: rgba(49, 15, 162, 0.2);
$dropdownmenu: rgba(249, 249, 249, 1);
$lightpurple: rgba(87, 98, 213, 1);
$cx-inputbg: rgba(232, 232, 232, 0.2);
$cx-divider: rgba(213, 213, 213, 1);
$gray-description: rgba(64, 62, 66, 1);
$purple-social: rgba(113, 123, 230, 1);
$search-input-background: #F9F9F9;
$gray-input: rgba(232, 232, 232, 0.50);
$gray-input-hover: rgba(232, 232, 232, 0.90);
$gray-input-focused: rgba(228, 228, 228, 1);
$light-gray-border: rgb(236, 237, 241);
$semi-light-gray-border: rgb(236, 237, 241, 0.5);
$dark-mustard: rgba(230, 187, 61, 1);
$handlebar-gray: rgba(84, 85, 112, 1);
$user-name-gray: rgba(77, 77, 77, 1);
$purple-icon: rgb(49, 15, 162, 0.4);
$honolulu-blue: rgba(0, 113, 178, 1);
$gray-default: rgba(146, 146, 149, 1);
$subtitle: rgba(33, 37, 41, 1);
$softwhite: rgba(238, 238, 238, 1);
$collapse-hover:rgb(239, 239, 253);
$collapse-answer: rgba(0, 0, 0, 0.698);
$collapse-shadow: rgba(0, 0, 0, 0.078);
$collpase-container-outline: rgba(0, 0, 0, 0.051);
$collpase-button: rgb(68, 68, 68);
$collapsible-divider: rgba(0, 0, 0, 0.953);
$question-fade-primary: rgba(250, 250, 255, 0);
$question-fade-secondary: rgba(250, 250, 255, 1);
$question-fade-hover-primary: rgba(250, 250, 255, 0);
$question-fade-hover-secondary: rgb(239, 239, 253);
$faq-border-color: rgba(231, 231, 231, 1);
$snackbar-info: rgba(82, 115, 202, 1);
$snackbar-error: rgba(84, 49, 61, 1);
$snackbar-warning: rgba(84, 77, 66, 1);
$snackbar-success: rgba(44, 79, 77, 1);
$warning-filled-state-layer-color: rgba(255, 247, 224, 1);
$warning-filled-label-text-color: rgba(140, 106, 1, 1);
$color-grey-1100: rgba(6, 6, 6, 1);
$color-grey-1000: rgba(31, 31, 31, 1);
$color-grey-900: rgba(56, 56, 56, 1);
$color-grey-800: rgba(82, 82, 82, 1);
$color-grey-700: rgba(107, 107, 107, 1);
$color-grey-600: rgba(133, 133, 133, 1);
$color-grey-500: rgba(182, 182, 182, 1);
$color-grey-450: rgba(213, 213, 213, 1);
$color-grey-400: rgba(228, 228, 228, 1);
$color-grey-350: rgba(235, 235, 235, 1);
$color-grey-300: rgba(231, 231, 231, 1);
$color-grey-250: rgb(242, 242, 242, 1);
$color-grey-200: rgba(247, 247, 247, 1);
$color-grey-100: rgba(252, 252, 252, 1);
$color-secondary-600: rgba(27, 15, 162, 1);
$color-accent-1100: rgba(26, 0, 0, 1);
$color-accent-500: rgba(64, 74, 101, 1);
$primary-600: rgba(113, 123, 230, 1);
$primary-300: rgba(221, 221, 255, 1);
$primary-200: rgba(239, 239, 253, 1);
$black-olive: rgba(59, 59, 59, 1);

$primary-button-pressed: rgba(0, 0, 111, 1);
$primary-button-hover: rgba(24, 0, 136, 1);

$tooltip-primary: rgba(49, 15, 162, 1);
$purple-navbar-hover: rgba(70, 39, 171, 1);
$purple-navbar-focus: rgba(90, 63, 181, 1);
$main-title-without-bg: rgba(6, 6, 6, 1);
$divider-color: rgba(64, 74, 101, 1);
$regular-border: 1px solid var(--cg-grey-400);
$grid-width-desktop: 1560px;
$default-grid-spacing: 16px;
$state-layer-color: rgba(235, 235, 235, 1);

$navbar-text-purple: rgb(49, 15, 162);

$smallphone: 320px;
$phone: 576px;
$tablet: 768px;
$desktop: 992px;
$widescreen: 1200px;
$extrascreen: 1400px;
$extrawidescreen: 1550px;
$extralargescreen: 1920px;
$quadhdscreen: 2048px;

$thinborder: 4px;
$tinyPad: 5px;
$smallPad: 10px;
$smallPadPlus: 15px;
$regularPad: 20px;
$mediumPad: 30px;
$bigPad: 40px;
$extrabigpad: 50px;
$largePad: 60px;
$hugePad: 80px;

@mixin background-properties() {
    background: transparent linear-gradient(90deg,
            rgba(17, 22, 52, 1) 0%,
            rgba(18, 28, 74, 1) 49%,
            rgba(17, 22, 52, 1) 100%) 0 0 no-repeat padding-box;
    /* w3c */
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin square($size, $important: false) {
    @if $important {
        $value: $value !important;
    }

    width: $size;
    height: $size;
}

@mixin truncate-text($lines, $break:false) {
        display: -webkit-box;
        -webkit-line-clamp: $lines;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @if $break {
            word-break: break-all;
        }
}
