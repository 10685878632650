@use 'styles/mixins' as m;
@use 'styles/variables' as v;

#cx-section {
    .title {
        @include m.font(v.$extralarge, 500, $color: var(--Color-Grey-1100));
        margin-right: 100%;
        margin-bottom: 4px;
    }

    .title, 
    .description {
        display: inline-block;
    }

    @include m.media(v.$desktop, false) {
        padding: v.$regularPad;
    }
}
