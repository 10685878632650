@use 'styles/variables' as v;
@use '@chicksgroup/styles/mixins' as m;
@forward '@chicksgroup/styles/colors';

#cx-seo-content {
    padding-inline: 0;

    .collapse-content {
        padding-bottom: 12px;

        hr {
            margin-top: 0;
        }

        @media (width >= v.$desktop) {
            padding-bottom: v.$regularPad;
        }
    }

    @include m.media-exclusive(v.$extrawidescreen) {
        margin-inline: v.$regularPad;
        @include m.container-fluid(calc(100% - v.$bigPad));
    }
    
    @media (width >= v.$extrawidescreen) {
        @include m.container-fluid(1560px);        
    }

    .content-sections {
        @include m.flex-gap(v.$bigPad, $direction: column);

        &:last-of-type {
            @media (width >= v.$extrawidescreen) {
                margin-bottom: 32px;
            }
        }

        .paragraph-title {
            h1, h2, p {
                @include m.font(v.$extralarge, 700, v.$huge, 0.05em, v.$color-grey-800);
                margin-bottom: v.$regularPad;
            }
        }
        
        .list-content {
            @include m.grid-gap(v.$bigPad,true,  false, 1);

            @media (width >= v.$desktop) {
                @include m.grid-gap(v.$bigPad, false, true, 3);
                
            }

            &__item {
                @include m.flex-gap(28px, center, initial, column);

                @media (width >= v.$phone) {
                    flex-direction: row;
                    align-items: start;                       
                }

                .list-content {
                    text-align: center;

                    @media (width >= v.$phone) {
                        text-align: left;                            
                    }

                    
                    &-title {
                        h1, h2, p {
                            @include m.font(v.$largeplus, 500, v.$extralarge, normal, v.$color-grey-800);
                            margin-bottom: 4px;
                            text-align: center;
    
                            @media (width >= v.$phone) {
                                text-align: left;                            
                            }
                        }
                    }

                    &-body {
                        p {
                            @include m.font(v.$medium, 400, v.$moreplushuge, 0.015em, v.$color-grey-600);
                            margin: 0;
                            text-align: center;
    
                            @media (width >= v.$phone) {
                                text-align: left;                            
                            }
                        }
                    }
                }
            }

            &__icon {
                @include m.circle(60px);
                min-width: 60px;
                min-height: 60px;
                background-color: rgba(242, 242, 242, 1);
            }
        }

        &__paragraph-image {            
            @include m.flex-gap(v.$bigPad, center, $direction: column-reverse);

            @media (width >= v.$desktop) {
                @include m.flex-gap(v.$bigPad, center);
            }

            .banner {
                height: 336px;
                max-height: 336px;                
    
                img {
                    height: 336px;
                    max-height: 336px;
                    border-radius: 4px;
                    object-fit: fill;
                }   

                @media (width >= v.$desktop) {
                    height: 400px;
                    max-height: 400px;
    
                    img {
                        height: 400px;
                        max-height: 400px;
                    }                    
                }

                @media (width >= v.$desktop) {
                    max-width: 450px;
                    max-height: 450px;
                    @include m.square(450px);
    
                    img {
                        height: 450px;
                        min-width: 450px;
                        max-height: 450px;
                    }
                }
            }

            .paragraph-image {
                &__title h1, h2, p {
                    @include m.font(null, 700, null, 0.05em);
                }
            }

            &.image-first {
                flex-flow: column-reverse;

                @media (width >= v.$desktop) {
                    flex-flow: row-reverse;
                    
                }
            }
        }

        &__banner {
            height: 164px;
            max-height: 164px;

            img {
                max-height: 164px;
                border-radius: 4px;
                object-fit: fill;
            }

            @media (width >= v.$phone) {
                height: 244px;
                max-height: 244px;

                img {
                    height: 244px;
                    max-height: 244px;
                }
            }
        }

        &__quad-cards-container {
            @include m.flex-gap(v.$bigPad, center, $direction: column);
            flex-flow: column-reverse;
            width: 100%;
            
            @media (width >= v.$desktop) {
                width: initial;
                @include m.flex-gap(v.$bigPad, center);
                flex-flow: row;
            }

            .cards {
                @include m.grid-square(v.$regularPad, true, 1);

                @media (width >= v.$phone) {
                    @include m.grid-square(v.$regularPad, true, 2);
                }
                
                .quad-card {
                    width: 100%;
                    height: 280px;
                    padding: v.$bigPad 28px;
                    border-radius: 4px;
                    background-color: rgba(242, 242, 242, 1);
                    @include m.flex-gap(20px, center, center, column);

                    @media (width >= v.$phone) {
                        height: 263px;
                    }

                    @media (width >= v.$desktop) {
                        width: 217px;
                    }

                    .qc-img img {
                        @include m.square(80px);
                        max-width: 80px;
                        max-height: 80px;
                        border-radius: 4px;
                        object-fit: fill;
                    }

                    &__title {
                        h1, h2, h3, h4, h5, p {
                            @include m.font(v.$medium, 400, v.$moreplushuge, 0.015em, v.$color-grey-800);
                            text-align: center;
                            margin-bottom: 4px;
                        }
                    }

                    &__body {
                        p {
                            @include m.font(v.$regular, 400, v.$extralarge, 0.001em, v.$color-grey-600);
                            text-align: center;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
