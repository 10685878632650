@use '@material/checkbox' as checkbox;
@use 'styles/variables' as v;

@mixin circle($value, $important: false) {
    @include square($value, $important);
    border-radius: 100%;
}

@mixin square($value, $important: false) {
    @if $important {
        $value: $value !important;
    }

    height: $value;
    width: $value;
}

@mixin square-padding($value, $padding) {
    @include square($value + ($padding * 2));
    padding: $padding;
}

@mixin padding-y($val, $half: false, $important: false) {
    @if $half {
        $val: calc($val / 2);
    }
    $val: get-important-val($val, $important);
    padding-top: $val;
    padding-bottom: $val;
}

@mixin padding-x($val, $half: false, $important: false) {
    @if $half {
        $val: calc($val / 2);
    }
    $val: get-important-val($val, $important);
    padding-left: $val;
    padding-right: $val;
}

@mixin margin-y($val) {
    margin-top: $val;
    margin-bottom: $val;
}

@mixin margin-x($val) {
    margin-left: $val;
    margin-right: $val;
}

@mixin flex($align: initial, $justify: initial, $direction: row, $important: false) {
    display: get-important-val(flex, $important);
    align-items: $align;
    justify-content: $justify;
    flex-direction: $direction;
}

@mixin flex-column($align: initial, $justify: initial) {
    @include flex($align, $justify, column);
}

@mixin flex-center($direction: row) {
    @include flex(center, center, $direction);
}

@mixin column-gap($gap, $align: initial, $justify: initial) {
    @include flex-gap($gap, $align, $justify, column);
}

@mixin flex-gap($gap, $align: initial, $justify: initial, $direction: row) {
    @include flex($align, $justify, $direction);
    gap: $gap;
}

@mixin flex-basis($width) {
    flex-basis: $width;
    max-width: $width;
}

@mixin full-size() {
    @include square(100%);
}

@mixin square-flex-center($size, $direction: row, $important: false) {
    @include square($size, $important);
    @include flex-center($direction);
}

@mixin container-fluid($max: 100%, $important: false, $min: 0, $width: 100%) {
    width: get-important-val($width, $important);
    min-width: get-important-val($min, $important);
    max-width: get-important-val($max, $important);
}

@mixin container-min-height-fluid($min: 100%, $important: false) {
    min-height: get-important-val($min, $important);
    height: get-important-val($min, $important);
}

@mixin no-spacing($important: false) {
    $val: get-important-val(0, $important);
    padding: $val;
    margin: $val;
}

@mixin media($val, $max: true, $offset: 0px) {
    @if $max {
        @media (width <= calc($val + $offset)) {
            @content;
        }
    } @else {
        @media (calc($val + $offset) <= width) {
            @content;
        }
    }
}

@mixin contentUrl($url) {
    content: url($url);
}

@mixin contentGreenIcon($iconName) {
    @include contentUrl("/icons/#{$iconName}-icon-green.svg")
}

@mixin border-radius-left($size) {
    border-top-left-radius: $size;
    border-bottom-left-radius: $size;
}

@mixin border-radius-right($size) {
    border-top-right-radius: $size;
    border-bottom-right-radius: $size;
}

@mixin border-radius-top($size) {
    border-top-right-radius: $size;
    border-top-left-radius: $size;
}

@mixin border-radius-bottom($size) {
    border-bottom-left-radius: $size;
    border-bottom-right-radius: $size;
}

@mixin media-exclusive($val, $max: true) {
    $offset: 1px;
    @if $max {
        $offset: -1px;
    }
    @include media($val, $max, $offset) {
        @content;
    }
}

@mixin media-range($min, $max) {
    @media (calc($min + 1px) <= width <= $max) {
        @content;
    }
}

@mixin truncate-text($lines, $break:false) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @if $break {
        word-break: break-all;
    }
}

@mixin grid($columns: false, $spaces: 1, $separation: 1fr) {
    display: grid;
    @if ($columns) {
        grid-template-columns: repeat($spaces, $separation);
    }
    @else {
        grid-template-rows: repeat($spaces, $separation);
    }
}

@mixin grid-square($gap, $columns: false, $spaces: 1, $separation: 1fr) {
    @include grid($columns, $spaces, $separation);
    gap: $gap;
}

@mixin grid-gap($gap, $vertical: false, $columns: false, $spaces: 1, $separation: 1fr) {
    @include grid($columns, $spaces, $separation);
    @if ($vertical) {
        row-gap: $gap;
    }
    @else {
        column-gap: $gap;
    }
}

@mixin fade($spacing, $color1: transparent, $color2: rgb(48, 49, 65), $height: 50px, $offset:-1px) {
    &:not(.touching-bottom)::after,
    &:not(.touching-top)::before {
        @include padding-x($spacing);
        content: "";
        height: $height;
        width: 100%;
        left: 0;
        position: absolute;
        display: block;
        z-index: 9;
    }

    &:not(.touching-bottom)::after {
        bottom: $offset;
        background: linear-gradient(180deg, $color1 0%, $color2 100%);
    }

    &:not(.touching-top)::before {
        top: $offset;
        background: linear-gradient(180deg, $color2 0%, $color1 100%);
    }
}

@mixin showOnHover($elements, $transition: false, $display: block) {
    @each $element in $elements {
        #{$element} {
            @if $transition {
                opacity: 0;
            }
            @else {
                display: none;
            }
        }

        &:hover {
            #{$element} {
                @if $transition {
                    opacity: 100;
                }
                @else {
                    display: $display;
                }

            }
        }

        @if $transition {
            #{$element} {
                transition: opacity $transition;
            }
        }
    }

    &:hover {
        @content;
    }
}

@mixin container-settings($size, $bottom, $sides, $section) {
    @media (width <= $size) {
        &:has(#{$section}) {
            padding-bottom: $bottom;

            .container-fluid {
                padding-left: $sides;
                padding-right: $sides;
            }
        }
    }
}

@mixin makeAnimation($name, $duration) {
    animation: $name $duration ease 0s 1 normal forwards;
    @keyframes #{$name} {
        100% {
            @content;
        }
    }
}

@mixin absoluteCenter($horizontal: true, $vertical: true) {
    position: absolute;

    @if ($vertical) {
        top: 0;
        bottom: 0;
    }

    @if ($horizontal) {
        left: 0;
        right: 0;
    }

    margin: auto;
}

@mixin absoluteOffset($size, $top: true, $right: true) {
    position: absolute;
    @include getPositions($size, $top, $right)
}

@mixin relativeOffset($size, $top: true, $right: true) {
    position: relative;
    @include getPositions($size, $top, $right)
}

@mixin fixedOffset($size, $top: true, $right: true) {
    position: fixed;
    @include getPositions($size, $top, $right);
}

@mixin getPositions($size, $top: true, $right: true) {
    @if ($top) {
        top: $size
    }
    @else {
        bottom: $size;
    }

    @if ($right) {
        right: $size;
    }
    @else {
        left: $size;
    }
}

@mixin optionIcon($size: 24px, $color: rgb(87, 98, 213), $padding : null) {
    @if $padding {
        @include square-padding($size, $padding);
    }
    @else {
        @include square($size);
    }
    @include flex-center();
    font-size: $size;
    color: $color;

    &--white {
        color: white;
        filter: brightness(0) invert(1);
    }
}

@mixin centerContainer($containerSize) {
    position: absolute;
    top: calc((100% - #{$containerSize}) / 2);
}

@mixin disable-select($important: false){
    $val: get-important-val(none, $important);
    -webkit-user-select: $val;
    -ms-user-select: $val;
    user-select: $val;
}

@mixin default-anchor($color: v.$purple-text) {
    a, span {
        color: $color;
        cursor: pointer;

        @include ul-hover(true);
    }
}

@mixin line-separators-items($selector) {
    #{$selector} { position: relative; }

    #{$selector}:not(:last-child)::after {
        content: "";
        position: absolute;
        width: calc(100% - 30px);
        height: 1px;
        bottom: 0;
        background-color: rgba($white, 0.2);
        z-index: 1;
        left: 15px;
    }
}

@mixin border-y($size, $color, $important: false) {
    $val: get-important-val($size solid $color, $important);
    border-top: $val;
    border-bottom: $val;
}

@mixin border-x($size, $color, $important: false) {
    $val: get-important-val($size solid $color, $important);
    border-left: $val;
    border-right: $val;
}

@mixin ul-hover($important: false) {
    &:hover {
        text-decoration: get-important-val(underline, $important);
    }
}

@mixin font($size: null, $weight: null, $height: normal, $spacing: null, $color: null, $opacity: null, $important: false) {
    line-height: if-value($height, $important);
    font-size: if-value($size, $important);
    font-weight: if-value($weight, $important);
    letter-spacing: if-value($spacing, $important);
    color: if-value($color, $important);
    opacity: if-value($opacity, $important);
}

@mixin default-font() {
    font-family: v.$primaryfont;
    margin-bottom: 0 !important;
}

@mixin notification($color) {
    border: 1px solid $color;
    color: $color !important;
}

@mixin has-any($values) {
    $selector-list: "";

    @each $value in $values {
        $selector-list: if($selector-list == "", "&:has(#{$value})", "#{$selector-list}, &:has(#{$value})");
    }

    #{$selector-list} {
        @content;
    }
}

@mixin media-has-any($resolution, $values) {
    @include media($resolution) {
        @include has-any($values) {
            @content;
        }
    }
}

@mixin regular-container-paddings($tablet: v.$tablet, $offset: 0px) {
    padding-block: v.$hugePad;

    @include media(calc(v.$tablet + $offset)) {
        padding-block: v.$largePad;
    }

    @include media(calc(v.$phone + $offset)) {
        padding-block: v.$bigPad;
    }
}

@mixin regular-border($hide: true, $resolution: v.$desktop, $radius: 4px) {
    border-radius: $radius;
    outline: v.$regular-border;
    outline-offset: -1px;

    @if ($hide) {
        @include media($resolution) {
            outline-color: transparent;
        }
    }

    @include has-any(('.skeleton-container', '.skeleton-loading')) {
        outline-color: transparent;
    }

    transition: outline-color 0.2s;
}

@mixin flex-top-bottom-handler($child) {
    #{$child} {
        &:nth-child(odd) {
            order: 1;
        }

        &:nth-child(even) {
            order: 2;
        }
    }
}

@mixin flex-top-bottom($child, $resolution) {
    @if ($resolution) {
        @include media($resolution, false) {
            @include flex-top-bottom-handler($child);
        }
    }
    @else {
        @include flex-top-bottom-handler($child);
    }
}

@mixin customerPortalStyle($radius: 4px) {
    background: v.$white;
    border-radius: $radius;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
}

@mixin box-default() {
    @include flex-column(initial,center);
    background-color: v.$white;
    padding: 8px v.$regularPad;
    border-radius: 4px;
    border: 1px solid v.$divisor-gray;
}

@mixin customerPortalStyle($radius: 4px) {
    background: v.$white;
    border-radius: $radius;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);

}

@mixin box-default() {
    @include flex-column(initial,center);
    background-color: v.$white;
    padding: 8px v.$regularPad;
    border-radius: 4px;
    border: 1px solid v.$divisor-gray;
}

@mixin flip-animation() {
    transform: scale(-1, -1);
}

@mixin size($width, $height, $important: false) {
    width: get-important-val($width, $important);
    height: get-important-val($height, $important);
}

@mixin size-flex-center($width, $height, $direction: row, $important: false) {
    @include size($width, $height, $important);
    @include flex-center($direction);
}

@mixin custom-checkbox($size, $checkmark-color: null, $active-color: null, $unfilled-color: null) {
    .mdc-checkbox {
        @include checkbox.container-colors(transparent, $unfilled-color, transparent, $active-color);
        @include checkbox.ink-color($checkmark-color);

        @if ($unfilled-color) {
            --mdc-checkbox-unchecked-color: #{$unfilled-color} !important;
        }

        $p: '.mdc-checkbox';
        @include no-spacing(true);
        flex: 0 0 $size;

        #{$p}__native-control {
            $offset: calc(-20px + $size / 2);
            @include absoluteOffset($offset, true, false);

            @if ($active-color) {
                &:enabled:checked~.mdc-checkbox__background {
                    background-color: $active-color !important;
                }
            }
        }

        #{$p}__background {
            @include absoluteOffset(0, true, false);
            border-color: transparent !important;
        }

        &,
        #{$p}__background,
        #{$p}__ripple,
        #{$p}__ripple:before {
            @include square($size);
        }
    }
}

@mixin skeleton-background($color: rgba(239, 239, 253, 1) !important) {
    background-color: $color;
    animation: skeletonFrames 2s linear infinite;
    opacity: 0.2;
}

// Functions

@function value-by-percent($value, $parent, $important: false) {
    $result: calc($value / $parent) * 100%;
    @return get-important-val($result, $important);
}

@function get-important-val($value, $important) {
    @return $value if($important, !important, null);
}

@function if-value($value, $important) {
    @return if($value, get-important-val($value, $important), null);
}

// Styles to extend

.profile-subtitle-extend {
    &, span, p {
        font-family: v.$primaryfont !important;
        @include font(v.$regular, 400, 16px, 0.035px, $color: rgb(82, 82, 82), $important: true);
    }
}

.skeleton-styles {
    background-color: v.$slateblue-off-low-opacity;
    border-radius: v.$thinborder;
    animation: skeletonLoading .5s linear infinite alternate;
}

@mixin any-direct-parent() {
    *:has(> &) {
        @content;
    }
}
